import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { PageTemplate } from 'components/PageTemplate';
import { Subroutes } from 'routes/Subroutes';
import {
  TenantContextProvider,
  TenantContext,
  TenantContextProps,
} from 'contexts/TenantContext/TenantContext';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { TenantSupportLogin } from 'components/TenantSupportLogin';
import { useLocation } from 'react-router-dom';
import { JobExecutionContextProvider } from 'contexts/JobExecutionContext';
import { TenantRouteProps, TenantRoutesProps } from './Tabs/TenantRoutes';
import { useAppRouteContext } from '../../contexts/AppRouteContext';
import { ConfigurationContextProvider } from 'contexts/ConfigurationContext';
import { ImageRegistryContext, ImageRegistryContextProvider } from 'contexts/ImageRegistryContext';

export interface TenantProps extends TenantRoutesProps {}

export const Tenant: React.FunctionComponent<TenantProps> = ({ routes }) => {
  const { tenantId } = useParams<{ tenantId: string }>();
  const loaded = React.useRef(false);
  const { t } = useTranslation();
  const { currentRoute } = useAppRouteContext();
  const currentTenantRoute = currentRoute as TenantRouteProps | undefined;

  const disabledTabIds = (tenantType: string | undefined) => {
    if (!tenantType) {
      return routes.filter((r) => r.tenantTypes).map((r) => r.path);
    }
    return routes
      .filter((r) => r.tenantTypes && !r.tenantTypes.includes(tenantType))
      .map((r) => r.path);
  };

  const enabledRoutes = (tenantType: string | undefined) => {
    if (!tenantType) {
      return routes.filter((r) => !r.tenantTypes);
    }
    return routes.filter((r) => !r.tenantTypes || r.tenantTypes.includes(tenantType));
  };

  return (
    <TenantContextProvider tenantId={tenantId!}>
      <TenantContext.Consumer>
        {(value: TenantContextProps) => {
          const { loading, tenant, error } = value;

          if (!loaded.current && !loading) {
            loaded.current = true;
          }

          const tenantType = tenant?.tenantType || 'Not Set';

          return (
            <>
              {!loaded.current && loading && (
                <LoadingIndicator
                  theme="light"
                  variant="fill-window"
                  data-testid="loading-tenant"
                />
              )}
              <JobExecutionContextProvider>
                {loaded.current && (
                  <ConfigurationContextProvider>
                    <ImageRegistryContextProvider>
                      <PageTemplate
                        breadcrumbProps={
                          tenant && { replaceText: { 'tenantProfile.pageTitle': tenant.name! } }
                        }
                        showTabs
                        title={tenant ? tenant.name! : t('tenant.notFoundTitle')}
                        errorMessage={
                          error && {
                            title: t('tenant.doesNotExist'),
                            message: error.message,
                          }
                        }
                        headerControl={
                          currentTenantRoute?.headerActionComponent ? (
                            <currentTenantRoute.headerActionComponent />
                          ) : null
                        }
                        disabledTabIds={disabledTabIds(tenantType)}
                        tabProps={{ wrapped: true }}
                      >
                        <Subroutes routes={enabledRoutes(tenantType)} />
                      </PageTemplate>
                    </ImageRegistryContextProvider>
                  </ConfigurationContextProvider>
                )}
              </JobExecutionContextProvider>
            </>
          );
        }}
      </TenantContext.Consumer>
    </TenantContextProvider>
  );
};
