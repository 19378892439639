import { useTranslation } from 'react-i18next';
import * as React from 'react';
import {
  OnSortChangedParams,
  SortDirection,
} from '@bb-ui/react-library/dist/components/SortableTable/SortableTable.types';
import { get, orderBy } from 'lodash';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { TableHead } from '@bb-ui/react-library/dist/components/TableHead';
import { TableRow } from '@bb-ui/react-library/dist/components/TableRow';
import { SortableTableHeaderCell } from '@bb-ui/react-library/dist/components/SortableTableHeaderCell';
import { TableCell } from '@bb-ui/react-library/dist/components/TableCell';
import { Link } from 'react-router-dom';
import { ChevronRight } from '@bb-ui/icons/dist/small/ChevronRight';
import { IconButton } from '@bb-ui/react-library';
import { createStyles, makeStyles } from '@material-ui/core';
import { UnderConstruction } from '../UnderConstruction';
import { PaginatedTable } from '../PaginatedTable';
import { LoadingIndicator } from '../LoadingIndicator';
import { ErrorMessage } from '../ErrorMessage';
import { TenantData } from '../../App.types';

export const useStyles = makeStyles(() =>
  createStyles({
    enabledCell: {
      width: 128,
    },
  }),
);

export interface TenantListTableProps {
  tenants: TenantData[];
  error?: Error;
  loading?: boolean;
  searchText?: string;
}

// Loosely typing this, so we can use <ButtonBase> props.
const LooseIconButton = IconButton as any;

export const TenantListTable: React.FC<TenantListTableProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles(props);

  const search =
    (query: string) =>
    ({ name, id }: TenantData) =>
      name.toLowerCase().includes(query.toLowerCase()) || id.includes(query);

  // SORTING
  const [sortParams, setSortParams] = React.useState<Partial<OnSortChangedParams>>({});
  const sortedTenants = React.useMemo(() => {
    const { sortColumnId, sortDirection } = sortParams;
    if (sortColumnId && ['name', 'hostname', 'id'].includes(sortColumnId)) {
      return orderBy(
        props.tenants,
        [(tenant) => get(tenant, sortColumnId)?.toLowerCase()],
        sortDirection,
      );
    }
    if (sortColumnId === 'isHidden') {
      return orderBy(props.tenants, [(tenant) => tenant.isHidden], sortDirection);
    }
    return props.tenants;
  }, [sortParams, props.tenants]);

  const getAriaSortMessage = (columnId?: string, sortDirection?: SortDirection) => {
    const columnLabel = t(`tenantList.${columnId}`);
    const orderLabel =
      sortDirection === 'asc'
        ? t('global.paginatedTable.ascending')
        : t('global.paginatedTable.descending');
    return t('global.paginatedTable.sortedAriaMessage', { columnLabel, orderLabel });
  };

  let content: React.ReactElement;

  if (props.error) {
    content = (
      <ErrorMessage
        data-testid="fnds-tenant-list-error"
        title={t('tenantList.loadError')}
        message={props.error.message}
        variant="block"
      />
    );
  } else if (props.loading) {
    content = <LoadingIndicator data-testid="fnds-tenant-list-init" />;
  } else if (props.tenants.length === 0) {
    content = (
      <Typography data-testid="fnds-tenant-list-no-data">{t('tenantList.noData')}</Typography>
    );
  } else {
    content = (
      <PaginatedTable
        onSortChanged={(sortParams) => {
          setSortParams(sortParams);
          return true;
        }}
        getSortChangedAriaMessage={getAriaSortMessage}
        searchBoxProps={{ label: props.searchText }}
        sortedData={sortedTenants}
        search={search}
        noMatchesMessage={(searchExpression) => t('tenantList.noMatch', { searchExpression })}
        renderHead={() => (
          <TableHead>
            <TableRow>
              <SortableTableHeaderCell
                id="tenant-list-table-header-name"
                columnId="name"
                tooltip={t('tenantList.sortByName')}
              >
                {t('tenantList.name')}
              </SortableTableHeaderCell>
              <SortableTableHeaderCell
                id="tenant-list-table-header-hostname"
                columnId="hostname"
                tooltip={t('tenantList.sortByHostname')}
              >
                {t('tenantList.hostname')}
              </SortableTableHeaderCell>
              <SortableTableHeaderCell
                id="tenant-list-table-header-id"
                columnId="id"
                tooltip={t('tenantList.sortById')}
              >
                {t('tenantList.systemId')}
              </SortableTableHeaderCell>
              <TableCell id="tenant-list-table-header-licenses" tabIndex={-1} role="columnheader">
                {t('tenantList.licenses')}
              </TableCell>
              <SortableTableHeaderCell
                tableCellProps={{ className: classes.enabledCell }}
                id="tenant-list-table-header-hidden"
                columnId="isHidden"
                tooltip={t('tenantList.sortByIsHidden')}
              >
                {t('tenantList.hidden')}
              </SortableTableHeaderCell>
              <TableCell role="columnheader" />
            </TableRow>
          </TableHead>
        )}
        renderRow={({ name, hostname, id, isHidden, tenantType }, index) => (
          <TableRow key={id} aria-rowindex={index + 1} data-testid={`tenant-list-table-row-${id}`}>
            <TableCell
              aria-colindex={1}
              tabIndex={-1}
              aria-describedby="tenant-list-table-header-name"
            >
              {name}
            </TableCell>
            <TableCell
              aria-colindex={2}
              tabIndex={-1}
              aria-describedby="tenant-list-table-header-hostname"
            >
              {hostname}
            </TableCell>
            <TableCell
              aria-colindex={3}
              tabIndex={-1}
              aria-describedby="tenant-list-table-header-id"
            >
              {id}
            </TableCell>
            <TableCell
              aria-colindex={4}
              tabIndex={-1}
              aria-describedby="tenant-list-table-header-licenses"
            >
              <UnderConstruction />
            </TableCell>
            <TableCell
              aria-colindex={5}
              tabIndex={-1}
              aria-describedby="tenant-list-table-header-hidden"
            >
              {isHidden ? t('global.yes') : t('global.no')}
            </TableCell>
            <TableCell aria-colindex={6} tabIndex={-1}>
              <LooseIconButton
                aria-label={t('tenantList.moreInformation')}
                component={Link}
                to={tenantType === 'Learn' ? `/tenants/${id}/assets` : `/tenants/${id}`}
              >
                <Typography component="span" variant="inherit">
                  {t('global.view')}
                </Typography>
                <ChevronRight />
              </LooseIconButton>
            </TableCell>
          </TableRow>
        )}
      />
    );
  }

  return content;
};
