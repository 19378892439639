import React from 'react';
import { useRestApi } from 'hooks/useRestApi';
import { apiUrl } from 'utils/apiUrl';
import { useTenantContext } from 'contexts/TenantContext';

export interface ImageRegistryContextProps {
  imageFetch?: () => void;
  imagesLoading: boolean;
  imagesData?: Partial<Record<string, any>>;
  imagesError?: Error;
}

export const ImageRegistryContext = React.createContext<ImageRegistryContextProps>({
  imagesLoading: true,
});

export const useImageRegistryContext = () => React.useContext(ImageRegistryContext);

export const ImageRegistryContextProvider: React.FunctionComponent = (props) => {
  const { children } = props;
  const { tenant } = useTenantContext();
  const {
    fetch: imageFetch,
    data: images,
    error: imagesError,
    loading: imagesLoading,
  } = useRestApi(apiUrl('lct', `regions/${tenant?.region}/registry`));

  let context: ImageRegistryContextProps = {
    imagesLoading,
    imagesError,
    imageFetch,
  };

  if (images) {
    context = { imagesData: images, ...context };
  }

  return <ImageRegistryContext.Provider value={context}>{children}</ImageRegistryContext.Provider>;
};

export default ImageRegistryContextProvider;
