import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, createStyles, Theme } from '@bb-ui/react-library';
import {
  OnSortChangedParams,
  SortDirection,
} from '@bb-ui/react-library/dist/components/SortableTable/SortableTable.types';
import {
  TableRow,
  SortableTableHeaderCell,
  TableCell,
  TableHead,
  List,
  ListItem,
  ListItemText,
  Typography,
  IconButton,
} from '@bb-ui/react-library/dist/components';
import { get, orderBy } from 'lodash';
import { ClientData } from 'App.types';
import { PageTemplate } from 'components/PageTemplate';
import { PaginatedTable } from 'components/PaginatedTable';
import { ErrorMessage } from 'components/ErrorMessage';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { apiUrl } from 'utils/apiUrl';
import useRestApi from 'hooks/useRestApi';
import { Link } from 'react-router-dom';
import { ChevronRight } from '@bb-ui/icons/dist/small/ChevronRight';
import { AddLearnClientButton } from './AddLearnClientButton';

// Loosely typing this, so we can use <ButtonBase> props.
const LooseIconButton = IconButton as any;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    updateHeaderCell: {
      paddingLeft: '5px',
    },
    enabledCell: {
      width: 20,
    },
    pageTitleContainer: {
      marginBottom: theme.spacing(4),
      display: 'flex',
      justifyContent: 'flex-end',
    },
    tableCell: {
      padding: '2px 2px 2px 0px',
      width: 'auto',
      height: 'auto',
      paddingLeft: '3px',
    },
  }),
);

export const LearnClients: React.FunctionComponent = (props) => {
  const { t } = useTranslation();
  const classes = useStyles(props);
  const {
    data: clientData,
    loading: loadClientData,
    error,
  } = useRestApi(apiUrl('tenancy', `clients`));
  const clients = React.useMemo<ClientData[]>(() => {
    return (clientData?.items ?? []).filter(
      (client: ClientData) => !client.name.startsWith('Integration Test Client:'),
    );
  }, [clientData]);
  const [sortParams, setSortParams] = React.useState<Partial<OnSortChangedParams>>({});

  const sortedClients = React.useMemo(() => {
    const { sortColumnId, sortDirection } = sortParams;
    if (sortColumnId && sortDirection && ['name'].includes(sortColumnId)) {
      return orderBy(
        clients,
        [
          (client) => {
            const value = get(client, sortColumnId);
            return value ? value.toLowerCase() : '';
          },
        ],
        sortDirection,
      );
    }
    return clients;
  }, [clients, sortParams]);

  const getAriaSortMessage = (columnId?: string, sortDirection?: SortDirection) => {
    const columnLabel = t(`clientsList.${columnId}`);
    const orderLabel =
      sortDirection === 'asc'
        ? t('global.paginatedTable.ascending')
        : t('global.paginatedTable.descending');
    return t('global.paginatedTable.sortedAriaMessage', { columnLabel, orderLabel });
  };

  const search =
    (filter: string) =>
    ({ name }: ClientData) =>
      name.toLowerCase().includes(filter.toLowerCase());

  let content: React.ReactElement;

  if (error) {
    content = (
      <ErrorMessage
        data-testid="learn-clients-list-error"
        title={t('learnClients.loadError')}
        message={error.message}
        variant="block"
      />
    );
  } else if (loadClientData) {
    content = <LoadingIndicator data-testid="clients-list-init" />;
  } else if (clients.length === 0) {
    content = (
      <Typography data-testid="learn-clients-no-data">{t('learnClients.noData')}</Typography>
    );
  } else {
    content = (
      <PaginatedTable
        onSortChanged={(sortParams) => {
          setSortParams(sortParams);
          return true;
        }}
        search={search}
        searchBoxProps={{ label: t('learnClients.searchClients') }}
        getSortChangedAriaMessage={getAriaSortMessage}
        sortedData={sortedClients}
        noMatchesMessage={(searchExpression) => t('learnClients.noMatch', { searchExpression })}
        renderHead={() => (
          <TableHead>
            <TableRow>
              <SortableTableHeaderCell
                id="client-list-table-header-name"
                columnId="name"
                tooltip={t('learnClients.sortByName')}
              >
                {t('learnClients.name')}
              </SortableTableHeaderCell>
              <SortableTableHeaderCell
                id="client-list-table-header-primary-market"
                columnId="primary-market"
                tooltip={t('learnClients.primaryMarket')}
              >
                {t('learnClients.primaryMarket')}
              </SortableTableHeaderCell>
              <SortableTableHeaderCell
                id="client-list-table-header-secondary-market"
                columnId="secondary-market"
                tooltip={t('learnClients.secondaryMarket')}
              >
                {t('learnClients.secondaryMarket')}
              </SortableTableHeaderCell>
              <SortableTableHeaderCell
                id="client-list-table-header-city"
                columnId="city"
                tooltip={t('learnClients.sortByCity')}
              >
                {t('learnClients.city')}
              </SortableTableHeaderCell>
              <SortableTableHeaderCell
                id="client-list-table-header-state"
                columnId="state"
                tooltip={t('learnClients.sortByState')}
              >
                {t('learnClients.state')}
              </SortableTableHeaderCell>
              <SortableTableHeaderCell
                id="client-list-table-header-country"
                columnId="country"
                tooltip={t('learnClients.sortByCountry')}
              >
                {t('learnClients.country')}
              </SortableTableHeaderCell>
              <SortableTableHeaderCell
                id="client-list-table-header-team"
                columnId="team"
                tooltip={t('learnClients.sortByTeam')}
              >
                {t('learnClients.team')}
              </SortableTableHeaderCell>
              <SortableTableHeaderCell
                id="client-list-table-header-contact"
                columnId="contact"
                tooltip={t('learnClients.sortByContact')}
              >
                {t('learnClients.contact')}
              </SortableTableHeaderCell>
              <SortableTableHeaderCell
                id="client-list-table-header-external-ClientIds"
                columnId="externalIds"
                tooltip={t('learnClients.sortByExternalIds')}
              >
                {t('learnClients.externalIds')}
              </SortableTableHeaderCell>
              <TableCell />
            </TableRow>
          </TableHead>
        )}
        renderRow={(
          {
            id,
            name,
            primaryMarket,
            secondaryMarket,
            city,
            state,
            country,
            team,
            contact,
            externalIds,
          },
          index,
        ) => (
          <TableRow
            key={id}
            aria-rowindex={index + 1}
            data-testid={`clients-list-table-row-${index}`}
          >
            <TableCell
              className={classes.tableCell}
              aria-colindex={1}
              tabIndex={-1}
              aria-describedby="client-list-table-header-name"
            >
              {name}
            </TableCell>
            <TableCell
              className={classes.tableCell}
              aria-colindex={1}
              tabIndex={-1}
              aria-describedby="client-list-table-header-primary-market"
            >
              {primaryMarket}
            </TableCell>
            <TableCell
              className={classes.tableCell}
              aria-colindex={1}
              tabIndex={-1}
              aria-describedby="client-list-table-header-secondary-market"
            >
              {secondaryMarket}
            </TableCell>
            <TableCell
              className={classes.tableCell}
              aria-colindex={1}
              tabIndex={-1}
              aria-describedby="client-list-table-header-city"
            >
              {city}
            </TableCell>
            <TableCell
              className={classes.tableCell}
              aria-colindex={1}
              tabIndex={-1}
              aria-describedby="client-list-table-header-state"
            >
              {state}
            </TableCell>
            <TableCell
              className={classes.tableCell}
              aria-colindex={5}
              tabIndex={-1}
              aria-describedby="client-list-table-header-country"
            >
              {country}
            </TableCell>
            <TableCell
              className={classes.tableCell}
              aria-colindex={5}
              tabIndex={-1}
              aria-describedby="client-list-table-header-team"
            >
              {team.map((teamMember, index) =>
                Object.entries(teamMember).map(([key, value]) => (
                  <List key={index} className={classes.tableCell}>
                    <ListItem className={classes.tableCell}>
                      <ListItemText
                        primary={key}
                        secondary={value || 'N/A'}
                        className={classes.tableCell}
                      />
                    </ListItem>
                  </List>
                )),
              )}
            </TableCell>
            <TableCell
              className={classes.tableCell}
              aria-colindex={5}
              tabIndex={-1}
              aria-describedby="client-list-table-header-contact"
            >
              {contact.map((contactInfo, index) =>
                Object.entries(contactInfo).map(([key, value]) => (
                  <List key={index} className={classes.tableCell}>
                    <ListItem className={classes.tableCell}>
                      <ListItemText
                        primary={key}
                        secondary={value || 'N/A'}
                        className={classes.tableCell}
                      />
                    </ListItem>
                  </List>
                )),
              )}
            </TableCell>
            <TableCell
              className={classes.tableCell}
              aria-colindex={5}
              tabIndex={-1}
              aria-describedby="client-list-table-header-external-ids"
            >
              {Object.entries(externalIds).map(([key, value]) => (
                <List className={classes.tableCell}>
                  <ListItem className={classes.tableCell}>
                    <ListItemText primary={key} secondary={value} className={classes.tableCell} />
                  </ListItem>
                </List>
              ))}
            </TableCell>
            <TableCell aria-colindex={6} tabIndex={-1} className={classes.enabledCell}>
              <LooseIconButton
                aria-label={t('tenantList.moreInformation')}
                component={Link}
                to={`/client/${id}/assets`}
              >
                <Typography component="span" variant="inherit">
                  {t('global.view')}
                </Typography>
                <ChevronRight />
              </LooseIconButton>
            </TableCell>
          </TableRow>
        )}
      />
    );
  }

  return (
    <>
      <PageTemplate
        showTabs
        title={t('learnClients.pageTitle')}
        data-testid="client-list-page"
        headerControl={<AddLearnClientButton />}
      >
        {content}
      </PageTemplate>
    </>
  );
};

export default LearnClients;
