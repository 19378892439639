import useRestApi from 'hooks/useRestApi';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { apiUrl } from 'utils/apiUrl';
import { PaginatedTable } from 'components/PaginatedTable';
import { TableHead } from '@bb-ui/react-library/dist/components/TableHead';
import {
  OnSortChangedParams,
  SortDirection,
} from '@bb-ui/react-library/dist/components/SortableTable/SortableTable.types';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { ErrorMessage } from 'components/ErrorMessage';
import {
  SortableTableHeaderCell,
  TableCell,
  TableRow,
  Tooltip,
  makeStyles,
} from '@bb-ui/react-library';
import { Item, ResponseData } from './Images.types';
import { RemoveImagesButton } from './RemoveImagesButton';
import { EditImagesButton } from './EditImagesButton';
import { useTenantContext } from 'contexts/TenantContext';
import { useImageRegistryContext } from 'contexts/ImageRegistryContext';

const useStyles = makeStyles(() => ({
  tooltip: {
    margin: '0',
  },
  button: {
    minWidth: 'auto',
    height: '1.5rem',
    padding: '0.2rem 0.5rem',
    fontSize: '0.75rem',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.3rem',
    justifyContent: 'center',
  },
}));

export const Images: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { tenant } = useTenantContext();

  const { imagesData, imagesError, imagesLoading, imageFetch } = useImageRegistryContext();

  const images = React.useMemo<Item[]>(() => imagesData?.items ?? [], [imagesData]);

  const handleReload = () => {
    if (imageFetch) {
      imageFetch();
    }
  };

  const search =
    (filter: string) =>
    ({ amiId }: Item) =>
      amiId.toLowerCase().includes(filter.toLowerCase());

  const [sortParams, setSortParams] = React.useState<Partial<OnSortChangedParams>>({});

  const sortedImages = React.useMemo(() => {
    const { sortColumnId, sortDirection } = sortParams;
    const orderSign = sortDirection === 'asc' ? 1 : -1;

    const getComparator = (sortColumnId: string | undefined) => {
      switch (sortColumnId) {
        case 'imagesName':
          return (l1: { amiId: string }, l2: { amiId: string }) =>
            orderSign * l1.amiId.localeCompare(l2.amiId);
        case 'imagesVersion':
          return (l1: { version: string }, l2: { version: string }) =>
            orderSign * l1.version.localeCompare(l2.version);
        case 'imagesApproved':
          return (l1: { approved: boolean }, l2: { approved: boolean }) => {
            if (l1.approved === l2.approved) {
              return 0;
            }
            return orderSign * (l1.approved ? 1 : -1);
          };
        default:
          return null;
      }
    };

    const comparator = getComparator(sortColumnId);

    if (comparator) {
      return [...images].sort(comparator);
    }

    return images;
  }, [images, sortParams]);

  const getAriaSortMessage = (columnId?: string, sortDirection?: SortDirection) => {
    const columnLabel = t(`image.${columnId}`);
    const orderLabel =
      sortDirection === 'asc'
        ? t('global.paginatedTable.ascending')
        : t('global.paginatedTable.descending');
    return t('global.paginatedTable.sortedAriaMessage', { columnLabel, orderLabel });
  };

  let content: React.ReactElement;

  if (imagesLoading) {
    content = <LoadingIndicator data-testid="fnds-image-init" />;
  } else if (imagesError) {
    content = (
      <ErrorMessage
        title={t('images.loadError')}
        message={imagesError.message}
        data-testid="fnds-image-no-data"
      />
    );
  } else {
    content = (
      <PaginatedTable
        onSortChanged={(sortParams) => {
          setSortParams(sortParams);
          return true;
        }}
        getSortChangedAriaMessage={getAriaSortMessage}
        searchBoxProps={{ label: t('images.searchLabel') }}
        sortedData={sortedImages}
        search={search}
        noMatchesMessage={(searchExpression) => t('images.noMatch', { searchExpression })}
        renderHead={() => (
          <TableHead>
            <TableRow>
              <SortableTableHeaderCell
                id="images-table-header-image-name"
                columnId="imagesName"
                tooltip={t('images.sortByImageName')}
              >
                {t('images.imageName')}
              </SortableTableHeaderCell>
              <SortableTableHeaderCell
                id="images-table-header-image-version"
                columnId="imagesVersion"
                tooltip={t('images.sortByImageVersion')}
                tableCellProps={{ align: 'center' }}
              >
                {t('images.imageVersion')}
              </SortableTableHeaderCell>
              <SortableTableHeaderCell
                id="images-table-header-image-learn-instances"
                columnId="imagesApproved"
                tooltip={t('images.sortByImageApprovalStatus')}
                tableCellProps={{ align: 'center' }}
              >
                {t('images.imageApprovalStatus')}
              </SortableTableHeaderCell>
              <Tooltip
                placement="top"
                title={t('images.actionsToolTip') || ''}
                arrow={true}
                classes={{ tooltip: classes.tooltip }}
              >
                <TableCell align="center" style={{ maxWidth: '5rem' }}>
                  {t('images.imageActions')}
                </TableCell>
              </Tooltip>
            </TableRow>
          </TableHead>
        )}
        renderRow={(image, index) => (
          <TableRow
            key={`${image.amiId}-${image.version}`}
            aria-rowindex={index + 1}
            data-testid={`image-table-row-${image.amiId}`}
          >
            <TableCell aria-colindex={1} aria-describedby="image-table-header-image-id">
              {image.amiId}
            </TableCell>
            <TableCell
              aria-colindex={1}
              aria-describedby="image-table-header-image-version"
              align="center"
            >
              {image.version}
            </TableCell>
            <TableCell
              align="center"
              aria-colindex={3}
              aria-describedby="image-table-header-image-approved"
            >
              {image.approved === true ? 'Yes' : 'No'}
            </TableCell>
            <TableCell
              align="center"
              aria-colcount={4}
              aria-describedby="image-table-header-image_actions"
              style={{ maxWidth: '4rem' }}
            >
              <div className={classes.buttonContainer}>
                <EditImagesButton
                  amiId={image.amiId}
                  version={image.version}
                  approved={image.approved}
                  createdAt={image.createdAt}
                  onReload={handleReload}
                />
                <RemoveImagesButton
                  amiId={image.amiId}
                  version={image.version}
                  onReload={handleReload}
                />
              </div>
            </TableCell>
          </TableRow>
        )}
      />
    );
  }

  return <>{content}</>;
};
