import React from 'react';
import { Grid } from '@material-ui/core';
import { useParams } from 'react-router';
import { JobInputBox } from './components/JobInputBox';
import { JobOutputBox } from './components/JobOutputBox';
import { useJobExecutionContext } from '../../contexts/JobExecutionContext';
import { JobErrorBox } from './components/JobErrorBox';
import { ExecutionBox } from './components/ExecutionBox';
import { RegistrationBox } from './components/RegistrationBox';
import { SummaryWidget } from './components/SummaryWidget';
import { JobChildrenBox } from './components/JobChildrenBox';
import { JobLogsBox } from './components/JobLogsBox';

export const JobExecutionDetail: React.FC = () => {
  const { executionId } = useParams<{ executionId: string }>();
  const { setExecutionId, execution, refresh } = useJobExecutionContext();
  setExecutionId(executionId);
  React.useEffect(() => {
    const interval = setInterval(() => {
      if (
        [
          'IN_PROGRESS',
          'PENDING',
          'QUEUED',
          'CANCELLATION_REQUESTED',
          'EXECUTION_TIMEOUT_PENDING',
        ].includes(execution!.status)
      ) {
        refresh();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [execution, refresh]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <SummaryWidget />
      </Grid>
      <JobExecutionDetailGridItem>
        <RegistrationBox />
      </JobExecutionDetailGridItem>
      <JobExecutionDetailGridItem>
        <ExecutionBox />
      </JobExecutionDetailGridItem>
      <JobExecutionDetailGridItem>
        <JobChildrenBox />
      </JobExecutionDetailGridItem>
      <JobExecutionDetailGridItem>
        <JobLogsBox />
      </JobExecutionDetailGridItem>
      <JobExecutionDetailGridItem>
        <JobInputBox />
      </JobExecutionDetailGridItem>
      <JobExecutionDetailGridItem>
        <JobOutputBox />
      </JobExecutionDetailGridItem>
      {execution?.exception && (
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <JobErrorBox />
        </Grid>
      )}
    </Grid>
  );
};

const JobExecutionDetailGridItem: React.FC = (props) => (
  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
    {props.children}
  </Grid>
);
